<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Envíos'" />

    <!-- Select partner -->
    <v-autocomplete
      v-model="selectedPartner"
      :items="partners"
      item-text="name"
      label="Partner"
      return-object
      class="mt-4"
      @change="listenerCreditTransfersByPartner"
    />

    <!-- Data table creadit transfers -->
    <v-card v-if="!_.isEmpty(selectedPartner)" class="my-8">
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details />
      </v-card-title>

      <v-data-table :headers="tableHeaders" :items="creditTransfers" :search="search">
        <template v-slot:[`item.id`]="{ item }">
          <router-link :to="`/envio/${item.id}`">{{ item.id }}</router-link>
        </template>
        <template v-slot:[`item.offices_ids`]="{ item }">
          {{ getOfficeName(item.offices_ids) }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Banner from "@/components/elements/Banner";
import listeners from "@/firebase/listeners";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import _ from "lodash";

export default {
  name: "CreditTransfers",
  mixins: [FormatDateMixin],
  components: {
    Banner
  },
  data() {
    return {
      selectedPartner: {},
      search: "",

      // Credit transfers datatable headers
      tableHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Oficinas", value: "offices_ids", sortable: false },
        { text: "Dinero envío ", value: "amount_to_send", sortable: false, filterable: false },
        { text: "Transferencia a realizar", value: "amount_to_transfer", sortable: false, filterable: false },
        { text: "Estado", value: "status", sortable: false },
        { text: "Fecha creación", value: "created_at", filterable: false }
      ]
    };
  },
  computed: {
    ...mapState({
      partners: (state) => state.partners.partners,
      offices: (state) => state.offices.offices,
      creditTransfers: (state) => state.creditTransfers.creditTransfers
    }),
    _() {
      return _;
    }
  },
  async created() {
    await this.$store.dispatch("partners/getPartners");
    if (this.creditTransfers.length > 0) {
      await this.$store.dispatch("offices/getOffices", { filter: { "partner.id": this.selectedPartner.id } });
      this.selectedPartner = this.partners.find((e) => e.id === this.creditTransfers[0].partner_id);
    }
  },
  methods: {
    async listenerCreditTransfersByPartner() {
      await this.$store.dispatch("offices/getOffices", { filter: { "partner.id": this.selectedPartner.id } });
      this.$store.commit("creditTransfers/SET_CREDIT_TRANSFERS", []);
      listeners.stop_listener("creditTransfersByPartner");
      await listeners.creditTransfersByPartner(this.selectedPartner.id);
    },

    getOfficeName(officesIds) {
      let names = "";

      for (let i = 0; i < officesIds.length; i++) {
        const id = officesIds[i];
        const office = this.offices.find((e) => e.id === id);
        names += i < officesIds.length - 1 ? `${office.name}, ` : `${office.name}`;
      }

      return names;
    }
  }
};
</script>
